import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Bars } from "react-loader-spinner";
import { baseURL } from "../../../Config/config"

const YourComponent = () => {
  const { userId } = useParams();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${baseURL}/Admin/getfgbdetails`;

        const response = await fetch(url, {
          method: 'GET'
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        console.log("++++++++++++++++++++++++++++++++", response.data)
          ;
        const jsonData = await response.json();
        setData(jsonData.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  useEffect(() => {
    const filtered = data.filter((item) =>
      item && item.firstname && item.firstname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchTerm, data]);



  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  if (loading) {
    return <div className="Loader">
      <Bars height={80} width={80} color="#4fa94d" ariaLabel="bars-loading" />
    </div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>

      <div className="p-3 mb-2 bg-primary bg-gradient text-white text-center fw-bold d-flex justify-content-between align-items-center">
        <div className='me-2'>
          <Link to="/Adminview">
            <img src="/Images/left-arrow-new.png" height={50} width={50} alt="Back" />
          </Link>
        </div>
        <h3>FGB Details</h3>
        <div></div>
      </div>


      <div className='card m-3 shadow-lg'>

        <div className="d-flex justify-content-between align-items-center p-3">
          <div className="ms-auto">
            <div className="input-group">
              <input
                type="text"
                placeholder="Search by Full Name"
                value={searchTerm}
                onChange={handleSearchChange}
                className="form-control"
              />
            </div>
          </div>
        </div>





        <div className="table-container p-3" style={{ overflowY: 'auto' }}>
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-primary">
                <tr>
                  <th><strong>First Name</strong></th>
                  <th><strong>Last Name</strong></th>
                  <th><strong>Email Address</strong></th>
                  <th><strong>Phone Number</strong></th>
                  <th><strong>Address</strong></th>
                  <th><strong>Referred By</strong></th>
                </tr>
              </thead>

              <tbody>
                {filteredData
                  .slice(
                    currentPage * itemsPerPage,
                    (currentPage + 1) * itemsPerPage
                  )
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{item.firstname}</td>
                      <td>{item.lastname}</td>
                      <td>{item.emailaddress}</td>
                      <td>{item.phoneNumber}</td>
                      <td style={{width:"10px"}}>{item.address}</td>
                      <td>{item.referredBy}</td>
                    </tr>
                  ))}
              </tbody>


            </table>

          </div>

          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />




        </div>

      </div>
    </div>

  );
};

export default YourComponent;