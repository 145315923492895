// import React, { useEffect, useState } from "react";
// import { Bars } from "react-loader-spinner";
// import { Rate } from "antd";
// // import getToken from "../../Utils/JWT/token";
// import getUserIdFromToken from "../../Utils/JWT/jwt";
// import { baseURL } from "../../Config/config"
// import 'react-notifications/lib/notifications.css';
// import PendingVideo from "./PreviousVideo";
// import { useDispatch, useSelector } from "react-redux";
// import { postannecdotes, postRANKING, postQUIZ, fetchREVIEWDATA } from "../../Redux/Action/DatatableQuiz.action";

// function CardDetailsPage() {
//   const dispatch = useDispatch();

//   const userId = getUserIdFromToken();
//   // const accessTokenWithoutQuotes = getToken();
//   // const [batchvideo, setbatchvideo] = useState(null);
//   const [newlearningId, setewlearningId] = useState(null);

//   const [isLoading, setIsLoading] = useState(false);
//   const [data, setData] = useState([]);

//   const [correctness, setCorrectness] = useState({});
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [selectedQuiz, setSelectedQuiz] = useState(null);
//   const [selectedvideo, setSelectedvideo] = useState(null);

//   const [currentPage] = useState(0);
//   const [searchQuery] = useState("");
//   const [itemsPerPage] = useState(5);
//   const [clickedRankingButtons, setClickedRankingButtons] = useState([]);
//   const [clickedvideoButtons, setClickedvideoButtons] = useState([]);
//   const [showPopup, setShowPopup] = useState(false);
//   const [QuestionData, setQuestionData] = useState({
//     QuestionFeedback: '',
//   });
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(">>>>>>>>>>>>>>>>>>>>>")
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setQuestionData({ ...QuestionData, [name]: value });
//   };


//   const [ranking, setRanking] = useState({
//     delivery: "",
//     content: "",
//     level: "",
//     duration: "",
//   });

//   const { data: newdata, error } = useSelector((state) => state.reviewdata);

//   useEffect(() => {
//     setData(newdata || []);
//   }, [newdata]);

//   const fetchData = () => {

//     dispatch(fetchREVIEWDATA(ageGroup, title, setIsLoading));
//   };
//   const batchvideo = newdata?.length > 0 ? newdata[0].batch : null;

//   console.log("Batch Value:", batchvideo);

//   // setbatchvideo(batchValue || 0);


//   const uniqueData = Array.from(
//     new Set(
//       data.map((item) => JSON.stringify({ id: item.id, Topic: item.Topic }))
//     )
//   ).map((stringified) => JSON.parse(stringified));

//   const uniqueAgesMap = new Map();
//   const uniquelink = new Map();
//   const uniquetopic = new Map();

//   data.forEach((entry) => {
//     const key = `${entry.id}-${entry.Topic}`;
//     if (!uniquetopic.has(key)) {
//       uniquetopic.set(key, entry.Title);
//     }
//   });

//   data.forEach((entry) => {
//     const key = `${entry.id}-${entry.Topic}`;
//     if (!uniquelink.has(key)) {
//       uniquelink.set(key, entry.link);
//     }
//   });

//   data.forEach((entry) => {
//     const key = `${entry.id}-${entry.Topic}`;
//     if (!uniqueAgesMap.has(key)) {
//       uniqueAgesMap.set(key, entry.Age);
//     }
//   });

//   const handleCorrectnessChange = (questionId, value) => {
//     const updatedCorrectness = { [questionId]: value };

//     Object.keys(correctness).forEach((key) => {
//       if (key !== questionId) {
//         updatedCorrectness[key] = "";
//       }
//     });

//     setCorrectness(updatedCorrectness);
//   };

//   const handleRankingChange = (category, value) => {
//     setRanking((prevRanking) => ({
//       ...prevRanking,
//       [category]: parseFloat(value),
//     }));
//   };

//   const calculateAverageRanking = () => {
//     const { delivery, content, level, duration } = ranking;
//     const averageRanking = (delivery + content + level + duration) / 4;
//     return averageRanking.toFixed(1);
//   };
//   const startIndex = currentPage * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;

//   const filteredData = searchQuery
//     ? uniqueData.filter((item) =>
//       item.Topic.toLowerCase().includes(searchQuery.toLowerCase())
//     )
//     : uniqueData;

//   const currentData = filteredData.slice(startIndex, endIndex);

//   const closePopup = () => {
//     setSelectedItem(null);
//   };

//   const openRankingPopup = (id) => {
//     setSelectedQuiz(id);
//   };

//   const closeRankingPopup = () => {
//     setSelectedQuiz(null);
//   };

//   const openRankingPopupvideo = (id) => {
//     setSelectedvideo(id);
//   };

//   const closeRankingPopupvideo = () => {
//     setSelectedvideo(null);
//   };

//   const resetInputValues = () => {
//     setInputValues({
//       Delivery: "",
//       Content: "",
//       level: "",
//       Duration: "",
//     });
//   };

//   const [inputValues, setInputValues] = useState({
//     Delivery: "",
//     Content: "",
//     level: "",
//     Duration: "",
//     rankingfeedback: ""
//   });

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setInputValues({
//       ...inputValues,
//       [name]: value,
//     });
//   };

//   const [inputValuesAnedotes, setInputValuesAnedotes] = useState({
//     Anedotes1: "",
//     Anedotes2: "",
//     Anedotes3: "",
//     Anedotes4: "",
//     Anedotestime1: "",
//     Anedotestime2: "",
//     Anedotestime3: "",
//     Anedotestime4: "",
//     tags1: "",
//     tags2: "",
//     tags3: "",
//     tags4: "",
//     tags5: "",
//     tags6: "",
//     tags7: "",
//     tags8: "",
//     tags9: "",
//     tags10: "",
//     suggestedLinks: "",
//     Annecdotesfeedback: ""
//   });

//   const handleInputChangeAnedotes = (event) => {
//     const { name, value } = event.target;
//     setInputValuesAnedotes({
//       ...inputValuesAnedotes,
//       [name]: value,
//     });
//   };




//   const saveRow = (id, quiz, quizId, selectedOption) => {
//     const postData = {
//       quizName: quiz,
//       answer: selectedOption,
//       learningId: id,
//       userId: userId,
//       quizId: quizId,
//       batch: batchvideo,
//       QuestionFeedback: QuestionData.QuestionFeedback
//     };

//     dispatch(postQUIZ(postData));
//     fetchData();
//     resetInputValues();
//     setQuestionData({
//       QuestionFeedback: ""
//     })

//   }


//   const extractVideoId = (link) => {
//     const videoIdMatch = link.match(/v=([A-Za-z0-9_-]{11})/);
//     return videoIdMatch ? videoIdMatch[1] : null;
//   };


//   const saveanedotes = () => {
//     const finaldata = {
//       Anedotes1: inputValuesAnedotes.Anedotes1,
//       Anedotes2: inputValuesAnedotes.Anedotes2,
//       Anedotes3: inputValuesAnedotes.Anedotes3,
//       Anedotes4: inputValuesAnedotes.Anedotes4,
//       Anedotestime1: inputValuesAnedotes.Anedotestime1,
//       Anedotestime2: inputValuesAnedotes.Anedotestime2,
//       Anedotestime3: inputValuesAnedotes.Anedotestime3,
//       Anedotestime4: inputValuesAnedotes.Anedotestime4,
//       tags1: inputValuesAnedotes.tags1,
//       tags2: inputValuesAnedotes.tags2,
//       tags3: inputValuesAnedotes.tags3,
//       tags4: inputValuesAnedotes.tags4,
//       tags5: inputValuesAnedotes.tags5,
//       tags6: inputValuesAnedotes.tags6,
//       tags7: inputValuesAnedotes.tags7,
//       tags8: inputValuesAnedotes.tags8,
//       tags9: inputValuesAnedotes.tags9,
//       tags10: inputValuesAnedotes.tags10,
//       suggestedLinks: inputValuesAnedotes.suggestedLinks,
//       learningId: selectedvideo,
//       userId: userId,
//       Annecdotesfeedback: inputValuesAnedotes.Annecdotesfeedback
//     };

//     dispatch(postannecdotes(finaldata));


//     setInputValuesAnedotes({
//       Anedotes1: "",
//       Anedotes2: "",
//       Anedotes3: "",
//       Anedotes4: "",
//       Anedotestime1: "",
//       Anedotestime2: "",
//       Anedotestime3: "",
//       Anedotestime4: "",
//       tags1: "",
//       tags2: "",
//       tags3: "",
//       tags4: "",
//       tags5: "",
//       tags6: "",
//       tags7: "",
//       tags8: "",
//       tags9: "",
//       tags10: "",
//       suggestedLinks: "",
//     });

//     setSelectedvideo(false);
//   }


//   const savevideoranking = () => {
//     const finaldatavideo = {
//       Delivery: inputValues.Delivery,
//       ContentQuality: inputValues.Content,
//       EngagementLevel: inputValues.level,
//       Duration: inputValues.Duration,
//       rankdelivery: ranking.delivery,
//       rankContentQuality: ranking.content,
//       rankEngagementLevel: ranking.level,
//       rankDuration: ranking.duration,
//       totalRankaverage: calculateAverageRanking(),
//       learningId: selectedQuiz,
//       userId: userId,
//       rankingfeedback: inputValues.rankingfeedback,
//       batch: batchvideo
//     };
//     dispatch(postRANKING(finaldatavideo));


//     setInputValues({
//       Delivery: "",
//       Content: "",
//       level: "",
//       Duration: "",
//     })
//     setRanking({
//       delivery: "",
//       content: "",
//       level: "",
//       duration: "",
//     })

//     setSelectedQuiz(false);
//   }
//   const [ageGroup, setAgeGroup] = useState('');
//   const [title, setTitle] = useState('');
//   const [ageGroupOptions, setAgeGroupOptions] = useState([]);
//   const [titleOptions, setTitleOptions] = useState([]);

//   useEffect(() => {
//     fetch(`${baseURL}/Admin/get/All/Age`)
//       .then(response => response.json())
//       .then(data => {
//         setAgeGroupOptions(data.data);
//       })
//       .catch(error => {
//         console.error('Error fetching age groups:', error);
//       });

//     fetch(`${baseURL}/Admin/get/All/title`)
//       .then(response => response.json())
//       .then(data => {
//         setTitleOptions(data.data);
//       })
//       .catch(error => {
//         console.error('Error fetching titles:', error);
//       });
//   }, []);

//   useEffect(() => {
//     fetchData();
//     setData([]);
//   }, [ageGroup, title]);

//   // useEffect(() => {
//   //   fetchData();
//   // }, []);

//   // const openpreviousvideo =()=>{
//   //   NotificationManager.success("Opening Previous Data","Success")
//   // }

//   const [showPendingVideo, setShowPendingVideo] = useState(false);

//   const openPreviousVideo = () => {
//     // Set the state to true when the button is clicked
//     // setShowPendingVideo(true);
//     setShowPendingVideo((prevValue) => !prevValue);

//   };

//   return (
//     <>
//       {isLoading && (
//         <div className="Loader">
//           <Bars
//             height={80}
//             width={80}
//             color="#4fa94d"
//             ariaLabel="bars-loading"
//           />
//         </div>
//       )}
//       <div class="p-1 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
//         <h3>Review Board</h3>
//       </div>
//       <div className="container">
//         <div className="row">
//           <div className="col-md-6">
//             <label>Select Age Group:</label>
//             <select
//               className="form-select"
//               value={ageGroup}
//               onChange={(e) => setAgeGroup(e.target.value)}
//             >
//               <option value="">--Select--</option>
//               {ageGroupOptions.map((option) => (
//                 <option key={option.ageGroup} value={option.ageGroup}>
//                   {option.ageGroup}
//                 </option>
//               ))}

//             </select>
//           </div>
//           <div className="col-md-6">
//             <label>Select Attribute:</label>
//             <select
//               className="form-select"
//               value={title}
//               onChange={(e) => setTitle(e.target.value)}
//             >
//               <option value="">--Select--</option>
//               {titleOptions.map((option) => (
//                 <option key={option.attribute} value={option.attribute}>
//                   {option.attribute}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>

//         <ul className="list-group mt-3">
//           {data.length === 0 ? (
//             <li className="list-group-item" style={{ color: "red" }}>Please select the age and attribute to see video's</li>
//           ) : (
//             data.map((item) => (
//               <li className="list-group-item" key={item.id} style={{ display: "none" }}>
//                 {item.Title} - Age: {item.Age} - Topic: {item.Topic}
//               </li>
//             ))
//           )}
//         </ul>
//       </div>

//       <div>
//         <div className="card-container overflow-auto">
//           {currentData.map((item, parentIndex) => (
//             <div className="container">
//               {data.map((item, parentIndex) => (
//                 <div
//                   className="card mb-3"
//                   key={`parent-${item.id}-${item.Topic}-${parentIndex}`}
//                 >
//                   <div className="card-header">
//                     {/* <h5>ID: {item.id}</h5> */}
//                     <p>Topic:- {item.Topic}</p>
//                     <p>Batch:- {batchvideo}</p>

//                     <p>Title:- {uniquetopic.get(`${item.id}-${item.Topic}`)}</p>
//                     <p>Age:- {uniqueAgesMap.get(`${item.id}-${item.Topic}`)}</p>
//                   </div>
//                   <div className="card-body">
//                     <div className="row">
//                       <div className="col-md-6">
//                         <iframe
//                           width="100%"
//                           height="315"
//                           src={`https://www.youtube.com/embed/${extractVideoId(
//                             uniquelink.get(`${item.id}-${item.Topic}`)
//                           )}?modestbranding=1`}
//                           frameBorder="0"
//                           allowFullScreen
//                         ></iframe>

//                         {data
//                           .filter(
//                             (entry) =>
//                               entry.id === item.id && entry.Topic === item.Topic
//                           )
//                           .map((entry, childIndex) => (
//                             <div
//                               key={`child-${entry.id}-${entry.Topic}-${entry.Quiz}-${childIndex}`}
//                             >
//                               <div class="d-flex flex-row mb-3">
//                                 <div class="p-2">
//                                   {" "}
//                                   {childIndex === 0 ? (
//                                     <div
//                                       rowSpan={
//                                         data.filter(
//                                           (e) =>
//                                             e.id === item.id &&
//                                             e.Topic === item.Topic
//                                         ).length
//                                       }
//                                     >
//                                       <button
//                                         className="btn btn-secondary"
//                                         onClick={() => {
//                                           openRankingPopup(entry.id);
//                                           setClickedRankingButtons(
//                                             (prevButtons) => [
//                                               ...prevButtons,
//                                               item.id,
//                                             ]
//                                           );
//                                         }}
//                                       >
//                                         Ranking
//                                       </button>
//                                     </div>
//                                   ) : null}
//                                 </div>
//                                 <div class="p-2">
//                                   {" "}
//                                   {childIndex === 0 ? (
//                                     <td
//                                       rowSpan={
//                                         data.filter(
//                                           (e) =>
//                                             e.id === item.id &&
//                                             e.Topic === item.Topic
//                                         ).length
//                                       }
//                                     >
//                                       <button
//                                         className="btn btn-secondary"
//                                         onClick={() => {
//                                           openRankingPopupvideo(entry.id);
//                                           setClickedvideoButtons(
//                                             (prevButtons) => [
//                                               ...prevButtons,
//                                               item.id,
//                                             ]
//                                           );
//                                         }}
//                                       >
//                                         Anecdotes & Tags
//                                       </button>
//                                     </td>
//                                   ) : null}
//                                 </div>
//                               </div>
//                             </div>
//                           ))}
//                       </div>
//                       <div className="col-md-6">
//                         {data
//                           .filter(
//                             (entry) =>
//                               entry.id === item.id && entry.Topic === item.Topic
//                           )
//                           .map((entry, childIndex) => (
//                             <div
//                               key={`child-${entry.id}-${entry.Topic}-${entry.Quiz}-${childIndex}`}
//                               className="mt-3"
//                             >
//                               <div className="row">
//                                 <div className="col-md-6">
//                                   <h4 className="responsive-text">
//                                     {entry.Quiz}
//                                   </h4>
//                                   {entry.options ? (
//                                     <ul className="list-group">
//                                       {Object.entries(entry.options).map(
//                                         ([key, value]) => (
//                                           <li
//                                             className="list-group-item responsive-text-options"
//                                             key={key}
//                                           >
//                                             <strong>{key}:</strong> {value}
//                                           </li>
//                                         )
//                                       )}
//                                     </ul>
//                                   ) : (
//                                     <div
//                                       className="alert alert-warning"
//                                       role="alert"
//                                     >
//                                       No options available
//                                     </div>
//                                   )}

//                                   <br></br>
//                                   <label>Enter Question Feedback (Optional)</label>
//                                   <textarea
//                                     type="text"
//                                     id="form1Example1"
//                                     class="form-control bg-light"
//                                     name="QuestionFeedback"
//                                     value={QuestionData.QuestionFeedback}
//                                     placeholder="(Optional)"
//                                     rows={5}
//                                     onChange={handleChange}
//                                   />
//                                 </div>
//                                 <div className="col-md-3 text-center">
//                                   <h6>Answer</h6>
//                                   <br />
//                                   <p>{entry.answer}</p>
//                                 </div>

//                                 <div className="col-md-3">
//                                   <p className="text-center" style={{ fontSize: "15px" }}><b>Is Answer Correct?</b> </p>

//                                   <div className="wrap">
//                                     <label className="label-radio">
//                                       <input
//                                         type="radio"
//                                         className="radio-input-c-n-n"
//                                         id={`correctness-yes-${entry.id}-${entry.Quiz}`}
//                                         name={`correctness-${entry.id}-${entry.Quiz}`}
//                                         value="Correct"
//                                         checked={
//                                           correctness[
//                                           `${entry.id}-${entry.Quiz}`
//                                           ] === "Correct"
//                                         }
//                                         onChange={() =>
//                                           handleCorrectnessChange(
//                                             `${entry.id}-${entry.Quiz}`,
//                                             "Correct"
//                                           )
//                                         }
//                                         onClick={(event) => {
//                                           event.preventDefault();
//                                           const selectedOption = "Correct";
//                                           saveRow(
//                                             entry.id,
//                                             entry.Quiz,
//                                             entry.quizId,
//                                             selectedOption
//                                           );
//                                         }}
//                                       />
//                                       <div className="radio-button-y-n-n">
//                                         <span>Yes</span>
//                                       </div>
//                                     </label>
//                                     <br />
//                                     <br />

//                                     <label>
//                                       <input
//                                         type="radio"
//                                         id={`correctness-no-${entry.id}-${entry.Quiz}`}
//                                         name={`correctness-${entry.id}-${entry.Quiz}`}
//                                         value="In-Correct"
//                                         className="radio-input-c-n-n"
//                                         checked={
//                                           correctness[
//                                           `${entry.id}-${entry.Quiz}`
//                                           ] === "In-Correct"
//                                         }
//                                         onChange={() =>
//                                           handleCorrectnessChange(
//                                             `${entry.id}-${entry.Quiz}`,
//                                             "In-Correct"
//                                           )
//                                         }
//                                         onClick={(event) => {
//                                           event.preventDefault();
//                                           const selectedOption = "In-Correct";
//                                           saveRow(
//                                             entry.id,
//                                             entry.Quiz,
//                                             entry.quizId,
//                                             selectedOption
//                                           );
//                                         }}
//                                       />
//                                       <div className="radio-button-y-n-n">
//                                         <span>No</span>
//                                       </div>
//                                     </label>
//                                     <br />
//                                     <br />

//                                     <label>
//                                       <input
//                                         type="radio"
//                                         className="radio-input-c-n-n"
//                                         id={`correctness-none-${entry.id}-${entry.Quiz}`}
//                                         name={`correctness-${entry.id}-${entry.Quiz}`}
//                                         placeholder="none"
//                                         value="none"
//                                         checked={
//                                           correctness[
//                                           `${entry.id}-${entry.Quiz}`
//                                           ] === "none"
//                                         }
//                                         onChange={() =>
//                                           handleCorrectnessChange(
//                                             `${entry.id}-${entry.Quiz}`,
//                                             "none"
//                                           )
//                                         }
//                                         onClick={(event) => {
//                                           event.preventDefault();
//                                           const selectedOption = "Not Sure";
//                                           saveRow(
//                                             entry.id,
//                                             entry.Quiz,
//                                             entry.quizId,
//                                             selectedOption
//                                           );
//                                         }}
//                                       />
//                                       <div className="radio-button-y-n-n">
//                                         <span>Not Sure</span>
//                                       </div>
//                                     </label>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div>
//                           ))}
//                       </div>
//                     </div>
//                   </div>
//                   <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                     <button onClick={openPreviousVideo}>Show Previous Video</button>
//                     {/* <i className="scroll-down"  aria-hidden="true" style={{ fontSize: '24px' }}></i> */}
//                     <div class="arrow bounce">
//                       <a class="fa fa-arrow-down fa-2x" href="#"></a>
//                     </div>
//                     {showPendingVideo && <PendingVideo />}
//                   </div>
//                 </div>
//               ))}
//             </div>
//           ))}
//         </div>

//         {showPopup && (
//           <div className="popup">
//             <div className="popup-content">
//               <button className="btn btn-primary" onClick={closePopup}>
//                 Close
//               </button>
//               <h2>Details of the selected item:</h2>
//               <p>Topic: {selectedItem.Topic}</p>
//             </div>
//           </div>
//         )}
//         {selectedvideo && (
//           <div className="popup">
//             <div className="popup-content">
//               <button
//                 className="btn-close text-white"
//                 onClick={closeRankingPopupvideo}
//               ></button>
//               {/* <h6>Quiz name: {selectedvideo}</h6> */}

//               <br />
//               <br />
//               <div class="d-flex flex-row mb-3 bg-secondary">
//                 <div class="p-2 w-50">
//                   <div>
//                     <h5
//                       className="modal-title text-center"
//                       id="exampleModalLabel"
//                     >
//                       Add Anecdotes
//                     </h5>
//                     <form>
//                       <div>
//                         <div>
//                           <div class="form-outline mb-4">
//                             <span style={{ fontSize: "15px" }}>
//                               Anecdotes 1
//                             </span>
//                             <input
//                               type="text"
//                               id="form1Example1"
//                               class="form-control bg-light"
//                               name="Anedotes1"
//                               value={inputValuesAnedotes.Anedotes1}
//                               onChange={handleInputChangeAnedotes}
//                               required
//                               rows="2"
//                             />
//                           </div>
//                           <div class="form-outline mb-4">
//                             <span style={{ fontSize: "15px" }}>
//                               Anecdotes 2
//                             </span>
//                             <input
//                               type="text"
//                               id="form1Example1"
//                               class="form-control bg-light"
//                               name="Anedotes2"
//                               value={inputValuesAnedotes.Anedotes2}
//                               onChange={handleInputChangeAnedotes}
//                               required
//                               rows="2"
//                             />
//                           </div>
//                           <div class="form-outline mb-4">
//                             <span style={{ fontSize: "15px" }}>
//                               Anecdotes 3
//                             </span>
//                             <input
//                               type="text"
//                               id="form1Example1"
//                               class="form-control bg-light"
//                               name="Anedotes3"
//                               value={inputValuesAnedotes.Anedotes3}
//                               onChange={handleInputChangeAnedotes}
//                               required
//                               rows="2"
//                             />
//                           </div>
//                           <div class="form-outline mb-4">
//                             <span style={{ fontSize: "15px" }}>
//                               Anecdotes 4
//                             </span>
//                             <input
//                               type="text"
//                               id="form1Example1"
//                               class="form-control bg-light"
//                               name="Anedotes4"
//                               value={inputValuesAnedotes.Anedotes4}
//                               onChange={handleInputChangeAnedotes}
//                               required
//                               rows="2"
//                             />
//                           </div>
//                         </div>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//                 &nbsp;&nbsp;&nbsp;
//                 <div class="vl"> </div>
//                 &nbsp;&nbsp;&nbsp;
//                 <div class="p-2 w-50">
//                   <div>
//                     <h5
//                       className="modal-title text-center"
//                       id="exampleModalLabel"
//                     >
//                       Add TimeStamp
//                     </h5>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Anecdotes Time 1</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="Anedotestime1"
//                         value={inputValuesAnedotes.Anedotestime1}
//                         onChange={handleInputChangeAnedotes}
//                         onKeyPress={(e) => {
//                           const isValidChar = /^[0-9:]+$/.test(e.key);
//                           if (!isValidChar) {
//                             e.preventDefault();
//                           }
//                         }}
//                         placeholder="MM:SS"
//                         required
//                         rows="2"
//                         maxLength={5}
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Anecdotes Time 2</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="Anedotestime2"
//                         value={inputValuesAnedotes.Anedotestime2}
//                         onChange={handleInputChangeAnedotes}
//                         onKeyPress={(e) => {
//                           const isValidChar = /^[0-9:]+$/.test(e.key);
//                           if (!isValidChar) {
//                             e.preventDefault();
//                           }
//                         }}
//                         placeholder="MM:SS"
//                         required
//                         rows="2"
//                         maxLength={5}
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Anedotes Time 3</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="Anedotestime3"
//                         value={inputValuesAnedotes.Anedotestime3}
//                         onChange={handleInputChangeAnedotes}
//                         onKeyPress={(e) => {
//                           const isValidChar = /^[0-9:]+$/.test(e.key);
//                           if (!isValidChar) {
//                             e.preventDefault();
//                           }
//                         }}
//                         placeholder="MM:SS"
//                         required
//                         rows="2"
//                         maxLength={5}
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Anedotes Time 4</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="Anedotestime4"
//                         value={inputValuesAnedotes.Anedotestime4}
//                         onChange={handleInputChangeAnedotes}
//                         onKeyPress={(e) => {
//                           const isValidChar = /^[0-9:]+$/.test(e.key);
//                           if (!isValidChar) {
//                             e.preventDefault();
//                           }
//                         }}
//                         placeholder="MM:SS"
//                         required
//                         rows="2"
//                         maxLength={5}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 &nbsp;&nbsp;&nbsp;
//                 <div class="vl"> </div>
//                 &nbsp;&nbsp;&nbsp;
//                 <div class="p-2 w-50">
//                   <div>
//                     <h5
//                       className="modal-title text-center"
//                       id="exampleModalLabel"
//                     >
//                       Add Tags
//                     </h5>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Tag 1</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="tags1"
//                         value={inputValuesAnedotes.tags1}
//                         onChange={handleInputChangeAnedotes}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Tag 2</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="tags2"
//                         value={inputValuesAnedotes.tags2}
//                         onChange={handleInputChangeAnedotes}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Tag 3</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="tags3"
//                         value={inputValuesAnedotes.tags3}
//                         onChange={handleInputChangeAnedotes}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Tag 4</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="tags4"
//                         value={inputValuesAnedotes.tags4}
//                         onChange={handleInputChangeAnedotes}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Tag 5</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="tags5"
//                         value={inputValuesAnedotes.tags5}
//                         onChange={handleInputChangeAnedotes}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 &nbsp;&nbsp;&nbsp;
//                 <div class="vl"> </div>
//                 &nbsp;&nbsp;&nbsp;
//                 <div class="p-2 w-50">
//                   <div>
//                     <h5
//                       className="modal-title text-center"
//                       id="exampleModalLabel"
//                     >
//                       Add Tags
//                     </h5>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Tag 6</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="tags6"
//                         value={inputValuesAnedotes.tags6}
//                         onChange={handleInputChangeAnedotes}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Tag 7</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="tags7"
//                         value={inputValuesAnedotes.tags7}
//                         onChange={handleInputChangeAnedotes}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Tag 8</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="tags8"
//                         value={inputValuesAnedotes.tags8}
//                         onChange={handleInputChangeAnedotes}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Tag 9</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="tags9"
//                         value={inputValuesAnedotes.tags9}
//                         onChange={handleInputChangeAnedotes}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Tag 10</span>
//                       <input
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="tags10"
//                         value={inputValuesAnedotes.tags10}
//                         onChange={handleInputChangeAnedotes}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <center>
//                 <div class="form-outline mb-4">
//                   <span style={{ fontSize: "15px" }}>suggestedLinks</span>
//                   <input
//                     type="text"
//                     id="form1Example1"
//                     class="form-control bg-secondary"
//                     name="suggestedLinks"
//                     value={inputValuesAnedotes.suggestedLinks}
//                     onChange={handleInputChangeAnedotes}
//                     required
//                     rows="2"
//                   />
//                 </div>
//                 <div class="form-outline mb-4">
//                   <span style={{ fontSize: "15px" }}>Anecdotes Feedback (Optional)</span>
//                   <input
//                     type="text"
//                     id="form1Example1"
//                     class="form-control bg-secondary"
//                     name="Annecdotesfeedback"
//                     value={inputValuesAnedotes.Annecdotesfeedback}
//                     onChange={handleInputChangeAnedotes}
//                     required
//                     rows="2"
//                   />
//                 </div>
//               </center>
//               <center>
//                 <button
//                   className="btn btn-primary"
//                   onClick={() => saveanedotes()}
//                 >
//                   Save
//                 </button>
//               </center>
//             </div>
//           </div>
//         )}

//         {selectedQuiz && (
//           <div className="popup">
//             <div className="popup-content">
//               <button
//                 className="btn-close text-white"
//                 onClick={closeRankingPopup}
//               ></button>
//               <br />
//               <br />

//               {/* <h6>Quiz name: {selectedQuiz}</h6> */}

//               <div class="d-flex flex-row mb-3 bg-secondary">
//                 <div class="p-2">
//                   <div>
//                     <h5
//                       className="modal-title text-center"
//                       id="exampleModalLabel"
//                     >
//                       Ranking
//                     </h5>

//                     <div>
//                       <div className="form-outline mb-4">
//                         <span style={{ fontSize: "15px" }}>Delivery</span>
//                         <br />
//                         <Rate
//                           allowHalf
//                           count={5}
//                           value={ranking.delivery}
//                           onChange={(value) =>
//                             handleRankingChange("delivery", value)
//                           }
//                           character={<i className="fas fa-star" />}
//                           style={{ fontSize: 24, color: "yellow" }}
//                         />
//                       </div>

//                       <div className="form-outline mb-4">
//                         <span style={{ fontSize: "15px" }}>
//                           Content Quality
//                         </span>
//                         <br />
//                         <Rate
//                           allowHalf
//                           count={5}
//                           value={ranking.content}
//                           onChange={(value) =>
//                             handleRankingChange("content", value)
//                           }
//                           character={<i className="fas fa-star" />}
//                           style={{ fontSize: 24, color: "yellow" }}
//                         />
//                       </div>

//                       <div className="form-outline mb-4">
//                         <span style={{ fontSize: "15px" }}>
//                           Engagement Level
//                         </span>
//                         <br />
//                         <Rate
//                           allowHalf
//                           count={5}
//                           value={ranking.level}
//                           onChange={(value) =>
//                             handleRankingChange("level", value)
//                           }
//                           character={<i className="fas fa-star" />}
//                           style={{ fontSize: 24, color: "yellow" }}
//                         />
//                       </div>

//                       <div className="form-outline mb-4">
//                         <span style={{ fontSize: "15px" }}>Duration</span>
//                         <br />
//                         <Rate
//                           allowHalf
//                           count={5}
//                           value={ranking.duration}
//                           onChange={(value) =>
//                             handleRankingChange("duration", value)
//                           }
//                           character={<i className="fas fa-star" />}
//                           style={{ fontSize: 24, color: "yellow" }}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 &nbsp;&nbsp;&nbsp;
//                 <div class="vl"> </div>
//                 &nbsp;&nbsp;&nbsp;
//                 <div class="p-2 w-50">
//                   <div>
//                     <h5
//                       className="modal-title text-center"
//                       id="exampleModalLabel"
//                     >
//                       Justification
//                     </h5>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Delivery</span>
//                       <textarea
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="Delivery"
//                         value={inputValues.Delivery}
//                         onChange={handleInputChange}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Content Quality</span>
//                       <textarea
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="Content"
//                         value={inputValues.Content}
//                         onChange={handleInputChange}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Engagement Level</span>
//                       <textarea
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="level"
//                         value={inputValues.level}
//                         onChange={handleInputChange}
//                         required
//                         rows="2"
//                       />
//                     </div>
//                     <div class="form-outline mb-4">
//                       <span style={{ fontSize: "15px" }}>Duration</span>
//                       <textarea
//                         type="text"
//                         id="form1Example1"
//                         class="form-control bg-light"
//                         name="Duration"
//                         value={inputValues.Duration}
//                         onChange={handleInputChange}
//                         required
//                         rows="2"
//                       />
//                     </div>

//                   </div>
//                 </div>

//               </div>
//               <div className="average-ranking bg-primary text-white">
//                 <h6>Average Ranking: {calculateAverageRanking()}</h6>
//                 <Rate
//                   allowHalf
//                   count={5}
//                   value={parseFloat(calculateAverageRanking())}
//                   character={<i className="fas fa-star" />}
//                   style={{ fontSize: 24, color: "yellow" }}
//                 />

//               </div>
//               <center>
//                 <br />
//                 <label>Video Ranking Feedback (Optional)</label>
//                 <div class="form-outline mb-4">
//                   <span style={{ fontSize: "15px" }}>Ranking Feedback</span>
//                   <textarea
//                     type="text"
//                     id="form1Example1"
//                     class="form-control bg-secondary"
//                     name="rankingfeedback"
//                     value={inputValues.rankingfeedback}
//                     onChange={handleInputChange}
//                     required
//                     placeholder="(Optional)"
//                     rows="2"
//                   />
//                 </div>
//                 <button
//                   className="btn btn-primary"
//                   onClick={() => savevideoranking()}
//                 >
//                   Save
//                 </button>
//               </center>
//             </div>
//           </div>
//         )}
//         {/* <NotificationContainer /> */}

//       </div>
//     </>
//   );
// }

// export default CardDetailsPage;



import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Bars } from "react-loader-spinner";
import { Rate } from "antd";
import getToken from "../../Utils/JWT/token";
import getUserIdFromToken from "../../Utils/JWT/jwt";
import { baseURL } from "../../Config/config"
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PendingVideo from "./PreviousVideo";

function CardDetailsPage() {
  const userId = getUserIdFromToken();
  const accessTokenWithoutQuotes = getToken();
  const [batchvideo, setbatchvideo] = useState(null);
  const [newlearningId, setewlearningId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [correctness, setCorrectness] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [selectedvideo, setSelectedvideo] = useState(null);

  const [currentPage] = useState(0);
  const [searchQuery] = useState("");
  const [itemsPerPage] = useState(5);
  const [clickedRankingButtons, setClickedRankingButtons] = useState([]);
  const [clickedvideoButtons, setClickedvideoButtons] = useState([]);
  const [showPopup] = useState(false);
  const [QuestionData, setQuestionData] = useState({
    QuestionFeedback: '',
  });
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(">>>>>>>>>>>>>>>>>>>>>")
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuestionData({ ...QuestionData, [name]: value });
  };


  const [ranking, setRanking] = useState({
    delivery: "",
    content: "",
    level: "",
    duration: "",
  });

  // let previousId = null;

  const fetchData = () => {
    setIsLoading(true);
    fetch(`${baseURL}/Admin/getattributes/all/attributes/${userId}/${ageGroup}/${title}`)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData.data || []);
        const firstItem = jsonData.data[0];
        console.log("?????????????????", firstItem.batch);
        setbatchvideo(firstItem.batch);
        setewlearningId(firstItem.id);
        // const newLearnId = firstItem.id.length -1;
        // console.log("?????????????????", newLearnId);
        // if (newLearnId === newLearnId) {
        //   alert("LearnID has changed!");
        // }
        const newId = firstItem.id;

        if (newId !== newlearningId) {
          // alert("ID has changed!");
          NotificationManager.success('Getting New Video', 'Success');

        }

      })

      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const uniqueData = Array.from(
    new Set(
      data.map((item) => JSON.stringify({ id: item.id, Topic: item.Topic }))
    )
  ).map((stringified) => JSON.parse(stringified));

  const uniqueAgesMap = new Map();
  const uniquelink = new Map();
  const uniquetopic = new Map();

  data.forEach((entry) => {
    const key = `${entry.id}-${entry.Topic}`;
    if (!uniquetopic.has(key)) {
      uniquetopic.set(key, entry.Title);
    }
  });

  data.forEach((entry) => {
    const key = `${entry.id}-${entry.Topic}`;
    if (!uniquelink.has(key)) {
      uniquelink.set(key, entry.link);
    }
  });

  data.forEach((entry) => {
    const key = `${entry.id}-${entry.Topic}`;
    if (!uniqueAgesMap.has(key)) {
      uniqueAgesMap.set(key, entry.Age);
    }
  });

  const handleCorrectnessChange = (questionId, value) => {
    const updatedCorrectness = { [questionId]: value };

    Object.keys(correctness).forEach((key) => {
      if (key !== questionId) {
        updatedCorrectness[key] = "";
      }
    });

    setCorrectness(updatedCorrectness);
  };

  const handleRankingChange = (category, value) => {
    setRanking((prevRanking) => ({
      ...prevRanking,
      [category]: parseFloat(value),
    }));
  };

  const calculateAverageRanking = () => {
    const { delivery, content, level, duration } = ranking;
    const averageRanking = (delivery + content + level + duration) / 4;
    return averageRanking.toFixed(1);
  };
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredData = searchQuery
    ? uniqueData.filter((item) =>
      item.Topic.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : uniqueData;

  const currentData = filteredData.slice(startIndex, endIndex);

  const closePopup = () => {
    setSelectedItem(null);
  };

  const openRankingPopup = (id) => {
    setSelectedQuiz(id);
  };

  const closeRankingPopup = () => {
    setSelectedQuiz(null);
  };

  const openRankingPopupvideo = (id) => {
    setSelectedvideo(id);
  };

  const closeRankingPopupvideo = () => {
    setSelectedvideo(null);
  };

  const resetInputValues = () => {
    setInputValues({
      Delivery: "",
      Content: "",
      level: "",
      Duration: "",
    });
  };

  const [inputValues, setInputValues] = useState({
    Delivery: "",
    Content: "",
    level: "",
    Duration: "",
    rankingfeedback: ""
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const [inputValuesAnedotes, setInputValuesAnedotes] = useState({
    Anedotes1: "",
    Anedotes2: "",
    Anedotes3: "",
    Anedotes4: "",
    Anedotestime1: "",
    Anedotestime2: "",
    Anedotestime3: "",
    Anedotestime4: "",
    tags1: "",
    tags2: "",
    tags3: "",
    tags4: "",
    tags5: "",
    tags6: "",
    tags7: "",
    tags8: "",
    tags9: "",
    tags10: "",
    suggestedLinks: "",
    Annecdotesfeedback: ""
  });

  const handleInputChangeAnedotes = (event) => {
    const { name, value } = event.target;
    setInputValuesAnedotes({
      ...inputValuesAnedotes,
      [name]: value,
    });
  };

  const saveRow = (id, quiz, quizId, selectedOption) => {

    setIsLoading(true);
    // fetchData();

    const postData = {
      quizName: quiz,
      answer: selectedOption,
      learningId: id,
      userId: userId,
      quizId: quizId,
      batch: batchvideo,
      QuestionFeedback: QuestionData.QuestionFeedback
    };

    fetch(`${baseURL}/Admin/quiz/table`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || "An error occurred");
          });
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.success) {
          // Swal.fire("Data saved successfully!", "", "success");
          fetchData();
          resetInputValues();
          setQuestionData({
            QuestionFeedback: ""
          })
          setShowPendingVideo(false);

        } else {
          // Swal.fire({
          //   icon: "error",
          //   title: "Oops...",
          //   text: responseData.data.message,
          // });
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Error saving data: " + error.message,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const extractVideoId = (link) => {
    const videoIdMatch = link.match(/v=([A-Za-z0-9_-]{11})/);
    return videoIdMatch ? videoIdMatch[1] : null;
  };

  const saveanedotes = (id) => {
    setIsLoading(true);
    const finaldata = {
      Anedotes1: inputValuesAnedotes.Anedotes1,
      Anedotes2: inputValuesAnedotes.Anedotes2,
      Anedotes3: inputValuesAnedotes.Anedotes3,
      Anedotes4: inputValuesAnedotes.Anedotes4,
      Anedotestime1: inputValuesAnedotes.Anedotestime1,
      Anedotestime2: inputValuesAnedotes.Anedotestime2,
      Anedotestime3: inputValuesAnedotes.Anedotestime3,
      Anedotestime4: inputValuesAnedotes.Anedotestime4,
      tags1: inputValuesAnedotes.tags1,
      tags2: inputValuesAnedotes.tags2,
      tags3: inputValuesAnedotes.tags3,
      tags4: inputValuesAnedotes.tags4,
      tags5: inputValuesAnedotes.tags5,
      tags6: inputValuesAnedotes.tags6,
      tags7: inputValuesAnedotes.tags7,
      tags8: inputValuesAnedotes.tags8,
      tags9: inputValuesAnedotes.tags9,
      tags10: inputValuesAnedotes.tags10,
      suggestedLinks: inputValuesAnedotes.suggestedLinks,
      learningId: selectedvideo,
      userId: userId,
      Annecdotesfeedback: inputValuesAnedotes.Annecdotesfeedback
    };

    fetch(`${baseURL}/Admin/post/anedotestags`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(finaldata),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || "An error occurred");
          });
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.success) {
          // Swal.fire("Data saved successfully!", "", "success");
          // alert("Data saved successfully!");
          NotificationManager.success('Data saved successfully!', 'Success');

          setInputValuesAnedotes({
            Anedotes1: "",
            Anedotes2: "",
            Anedotes3: "",
            Anedotes4: "",
            Anedotestime1: "",
            Anedotestime2: "",
            Anedotestime3: "",
            Anedotestime4: "",
            tags1: "",
            tags2: "",
            tags3: "",
            tags4: "",
            tags5: "",
            tags6: "",
            tags7: "",
            tags8: "",
            tags9: "",
            tags10: "",
            suggestedLinks: "",
          });
        } else {
          alert("DATA_ALREADY_EXISTS", responseData.data.message);
          NotificationManager.error(responseData.data.message, 'Error');
          // Swal.fire({
          //   icon: "error",
          //   title: "Oops...",
          //   text: responseData.data.message,
          // });
        }
      })
      .catch((error) => {
        // const errorMessage = "Error saving data: " + error.message;

        // alert(errorMessage);
        const errorMessage = 'Error saving data: ' + error.message;
        NotificationManager.error(errorMessage, 'Error');
        // Swal.fire({
        //   icon: "error",
        //   title: "Oops...",
        //   text: "Error saving data: " + error.message,
        // });
      })
      .finally(() => {
        setIsLoading(false);
        closeRankingPopupvideo();

      });
  };
  const savevideoranking = (id) => {

    setIsLoading(true);
    const finaldatavideo = {
      Delivery: inputValues.Delivery,
      ContentQuality: inputValues.Content,
      EngagementLevel: inputValues.level,
      Duration: inputValues.Duration,
      rankdelivery: ranking.delivery,
      rankContentQuality: ranking.content,
      rankEngagementLevel: ranking.level,
      rankDuration: ranking.duration,
      totalRankaverage: calculateAverageRanking(),
      learningId: selectedQuiz,
      userId: userId,
      rankingfeedback: inputValues.rankingfeedback,
      batch: batchvideo
    };

    fetch(`${baseURL}/Admin/add/videoranking`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessTokenWithoutQuotes}`,
      },
      body: JSON.stringify(finaldatavideo),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || "An error occurred");
          });
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.success) {
          // Swal.fire("Data saved successfully!", "", "success");
          // alert("Data saved successfully!");
          NotificationManager.success('Data saved successfully!', 'Success');

          setInputValues({
            Delivery: "",
            Content: "",
            level: "",
            Duration: "",
          })
          setRanking({
            delivery: "",
            content: "",
            level: "",
            duration: "",
          })
        } else {
          // alert("DATA_ALREADY_EXISTS", responseData.data.message);
          NotificationManager.error(responseData.data.message, 'Error');

          // Swal.fire({
          //   icon: "error",
          //   title: "Oops...",
          //   text: responseData.data.message,
          // });

          setInputValues({
            Delivery: "",
            Content: "",
            level: "",
            Duration: "",
          })
          setRanking({
            delivery: "",
            content: "",
            level: "",
            duration: "",
          })
        }
      })
      .catch((error) => {
        const errorMessage = "Error saving data: " + error.message;
        // alert(errorMessage);
        NotificationManager.error(errorMessage, 'Error');

        // Swal.fire({
        //   icon: "error",
        //   title: "Oops...",
        //   text: "Error saving data: " + error.message,
        // });
      })
      .finally(() => {
        setIsLoading(false);
        closeRankingPopup();

      });
  };
  const [ageGroup, setAgeGroup] = useState('');
  const [title, setTitle] = useState([]);
  const [ageGroupOptions, setAgeGroupOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);

  useEffect(() => {
    fetch(`${baseURL}/Admin/get/All/Age/${userId}`)
      .then(response => response.json())
      .then(data => {
        setAgeGroupOptions(data.data);
      })
      .catch(error => {
        console.error('Error fetching age groups:', error);
      });

    console.log("+++++++++++++++SSSSSS", ageGroup);

  }, []);

  useEffect(() => {
    fetch(`${baseURL}/Admin/get/All/title/${ageGroup}/${userId}`)
      .then(response => response.json())
      .then(data => {
        setTitleOptions(data.data);
      })
      .catch(error => {
        console.error('Error fetching titles:', error);
      });
  }, [ageGroup, userId]);

  const fetchnewtitle = () => {
    fetch(`${baseURL}/Admin/get/All/title/${ageGroup}/${userId}`)
      .then(response => response.json())
      .then(data => {
        setTitleOptions(data.data);
      })
      .catch(error => {
        console.error('Error fetching titles:', error);
      });

      fetch(`${baseURL}/Admin/get/All/Age/${userId}`)
      .then(response => response.json())
      .then(data => {
        setAgeGroupOptions(data.data);
      })
      .catch(error => {
        console.error('Error fetching age groups:', error);
      });
  }
  useEffect(() => {
    fetchData();
    fetchnewtitle();
  }, [ageGroup, title]);

  // const openpreviousvideo =()=>{
  //   NotificationManager.success("Opening Previous Data","Success")
  // }

  const [showPendingVideo, setShowPendingVideo] = useState(false);

  const openPreviousVideo = () => {
    // Set the state to true when the button is clicked
    // setShowPendingVideo(true);
    setShowPendingVideo((prevValue) => !prevValue);

  };

  return (
    <>
      {isLoading && (
        <div className="Loader">
          <Bars
            height={80}
            width={80}
            color="#4fa94d"
            ariaLabel="bars-loading"
          />
        </div>
      )}
      <div class="p-1 mb-2 bg-primary bg-gradient text-white text-center fw-bold">
        <h3>Review Board</h3>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <label>Select Age Group:</label>
            <select
              className="form-select"
              value={ageGroup}
              onChange={(e) => setAgeGroup(e.target.value)}
            >
              <option value="">--Select--</option>
              {ageGroupOptions.map((option) => (
                <option key={option.ageGroup} value={option.ageGroup}>
                  {option.ageGroup}
                </option>
              ))}

            </select>
          </div>
          <div className="col-md-6">
            <label>Select Attribute:</label>
            <select
              className="form-select"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            >
              <option value="">--Select--</option>
              {titleOptions.map((option) => (
                <option key={option.attribute} value={option.attribute}>
                  {option.attribute}
                </option>
              ))}
            </select>
          </div>
        </div>

        <ul className="list-group mt-3">
          {data.length === 0 ? (
            <li className="list-group-item" style={{ color: "red" }}>Please select the age and attribute to see video's</li>
          ) : (
            data.map((item) => (
              <li className="list-group-item" key={item.id} style={{ display: "none" }}>
                {item.Title} - Age: {item.Age} - Topic: {item.Topic}
              </li>
            ))
          )}
        </ul>
      </div>

      <div>
        <div className="card-container overflow-auto">
          {currentData.map((item, parentIndex) => (
            <div className="container">
              {data.map((item, parentIndex) => (
                <div
                  className="card mb-3"
                  key={`parent-${item.id}-${item.Topic}-${parentIndex}`}
                >
                  <div className="card-header">
                    {/* <h5>ID: {item.id}</h5> */}
                    <p>Topic:- {item.Topic}</p>
                    <p>Title:- {uniquetopic.get(`${item.id}-${item.Topic}`)}</p>
                    <p>Age:- {uniqueAgesMap.get(`${item.id}-${item.Topic}`)}</p>
                    <b>Batch:- {batchvideo}</b>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <iframe
                          title={`YouTube Video - ${item.id}-${item.Topic}`}
                          width="100%"
                          height="315"
                          src={`https://www.youtube.com/embed/${extractVideoId(
                            uniquelink.get(`${item.id}-${item.Topic}`)
                          )}?modestbranding=1`}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>

                        {data
                          .filter(
                            (entry) =>
                              entry.id === item.id && entry.Topic === item.Topic
                          )
                          .map((entry, childIndex) => (
                            <div
                              key={`child-${entry.id}-${entry.Topic}-${entry.Quiz}-${childIndex}`}
                            >
                              <div class="d-flex flex-row mb-3">
                                <div class="p-2">
                                  {" "}
                                  {childIndex === 0 ? (
                                    <div
                                      rowSpan={
                                        data.filter(
                                          (e) =>
                                            e.id === item.id &&
                                            e.Topic === item.Topic
                                        ).length
                                      }
                                    >
                                      <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                          openRankingPopup(entry.id);
                                          setClickedRankingButtons(
                                            (prevButtons) => [
                                              ...prevButtons,
                                              item.id,
                                            ]
                                          );
                                        }}
                                      >
                                        Ranking
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                                <div class="p-2">
                                  {" "}
                                  {childIndex === 0 ? (
                                    <td
                                      rowSpan={
                                        data.filter(
                                          (e) =>
                                            e.id === item.id &&
                                            e.Topic === item.Topic
                                        ).length
                                      }
                                    >
                                      <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                          openRankingPopupvideo(entry.id);
                                          setClickedvideoButtons(
                                            (prevButtons) => [
                                              ...prevButtons,
                                              item.id,
                                            ]
                                          );
                                        }}
                                      >
                                        Anecdotes & Tags
                                      </button>
                                    </td>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="col-md-6">
                        {data
                          .filter(
                            (entry) =>
                              entry.id === item.id && entry.Topic === item.Topic
                          )
                          .map((entry, childIndex) => (
                            <div
                              key={`child-${entry.id}-${entry.Topic}-${entry.Quiz}-${childIndex}`}
                              className="mt-3"
                            >
                              <div className="row">
                                <div className="col-md-6">
                                  <h4 className="responsive-text">
                                    {entry.Quiz}
                                  </h4>
                                  {entry.options ? (
                                    <ul className="list-group">
                                      {Object.entries(entry.options).map(
                                        ([key, value]) => (
                                          <li
                                            className="list-group-item responsive-text-options"
                                            key={key}
                                          >
                                            <strong>{key}:</strong> {value}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    <div
                                      className="alert alert-warning"
                                      role="alert"
                                    >
                                      No options available
                                    </div>
                                  )}

                                  <br></br>
                                  {/* <label>Enter Question Feedback (Optional)</label> */}
                                  <textarea
                                    type="text"
                                    id="form1Example1"
                                    class="form-control bg-light"
                                    name="QuestionFeedback"
                                    value={QuestionData.QuestionFeedback}
                                    placeholder="Enter Question Feedback (Optional)"
                                    rows={1}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-3 text-center">
                                  <h6>Answer</h6>
                                  <br />
                                  <p>{entry.answer}</p>
                                </div>

                                <div className="col-md-3">
                                  <p className="text-center" style={{ fontSize: "15px" }}><b>Is Answer Correct?</b> </p>

                                  <div className="wrap">
                                    <label className="label-radio">
                                      <input
                                        type="radio"
                                        className="radio-input-c-n-n"
                                        id={`correctness-yes-${entry.id}-${entry.Quiz}`}
                                        name={`correctness-${entry.id}-${entry.Quiz}`}
                                        value="Correct"
                                        checked={
                                          correctness[
                                          `${entry.id}-${entry.Quiz}`
                                          ] === "Correct"
                                        }
                                        onChange={() =>
                                          handleCorrectnessChange(
                                            `${entry.id}-${entry.Quiz}`,
                                            "Correct"
                                          )
                                        }
                                        onClick={(event) => {
                                          event.preventDefault();
                                          const selectedOption = "Correct";
                                          saveRow(
                                            entry.id,
                                            entry.Quiz,
                                            entry.quizId,
                                            selectedOption
                                          );
                                        }}
                                      />
                                      <div className="radio-button-y-n-n">
                                        <span>Yes</span>
                                      </div>
                                    </label>
                                    <br />
                                    <br />

                                    <label>
                                      <input
                                        type="radio"
                                        id={`correctness-no-${entry.id}-${entry.Quiz}`}
                                        name={`correctness-${entry.id}-${entry.Quiz}`}
                                        value="In-Correct"
                                        className="radio-input-c-n-n"
                                        checked={
                                          correctness[
                                          `${entry.id}-${entry.Quiz}`
                                          ] === "In-Correct"
                                        }
                                        onChange={() =>
                                          handleCorrectnessChange(
                                            `${entry.id}-${entry.Quiz}`,
                                            "In-Correct"
                                          )
                                        }
                                        onClick={(event) => {
                                          event.preventDefault();
                                          const selectedOption = "In-Correct";
                                          saveRow(
                                            entry.id,
                                            entry.Quiz,
                                            entry.quizId,
                                            selectedOption
                                          );
                                        }}
                                      />
                                      <div className="radio-button-y-n-n">
                                        <span>No</span>
                                      </div>
                                    </label>
                                    <br />
                                    <br />

                                    <label>
                                      <input
                                        type="radio"
                                        className="radio-input-c-n-n"
                                        id={`correctness-none-${entry.id}-${entry.Quiz}`}
                                        name={`correctness-${entry.id}-${entry.Quiz}`}
                                        placeholder="none"
                                        value="none"
                                        checked={
                                          correctness[
                                          `${entry.id}-${entry.Quiz}`
                                          ] === "none"
                                        }
                                        onChange={() =>
                                          handleCorrectnessChange(
                                            `${entry.id}-${entry.Quiz}`,
                                            "none"
                                          )
                                        }
                                        onClick={(event) => {
                                          event.preventDefault();
                                          const selectedOption = "Not Sure";
                                          saveRow(
                                            entry.id,
                                            entry.Quiz,
                                            entry.quizId,
                                            selectedOption
                                          );
                                        }}
                                      />
                                      <div className="radio-button-y-n-n">
                                        <span>Not Sure</span>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={openPreviousVideo}>Show Previous Video</button>
                    {/* <i className="scroll-down"  aria-hidden="true" style={{ fontSize: '24px' }}></i> */}
                    <div class="arrow bounce">
                      <p class="fa fa-arrow-down fa-2x"></p>
                    </div>
                    {showPendingVideo && <PendingVideo />}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <button className="btn btn-primary" onClick={closePopup}>
                Close
              </button>
              <h2>Details of the selected item:</h2>
              <p>Topic: {selectedItem.Topic}</p>
            </div>
          </div>
        )}
        {selectedvideo && (
          <div className="popup">
            <div className="popup-content">
              <button
                className="btn-close text-white"
                onClick={closeRankingPopupvideo}
              ></button>
              {/* <h6>Quiz name: {selectedvideo}</h6> */}

              <br />
              <br />
              <div class="d-flex flex-row mb-3 bg-secondary">
                <div class="p-2 w-50">
                  <div>
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Add Anecdotes
                    </h5>
                    <form>
                      <div>
                        <div>
                          <div class="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>
                              Anecdote 1
                            </span>
                            <input
                              type="text"
                              id="form1Example1"
                              class="form-control bg-light"
                              name="Anedotes1"
                              value={inputValuesAnedotes.Anedotes1}
                              onChange={handleInputChangeAnedotes}
                              required
                              rows="2"
                            />
                          </div>
                          <div class="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>
                              Anecdote 2
                            </span>
                            <input
                              type="text"
                              id="form1Example1"
                              class="form-control bg-light"
                              name="Anedotes2"
                              value={inputValuesAnedotes.Anedotes2}
                              onChange={handleInputChangeAnedotes}
                              required
                              rows="2"
                            />
                          </div>
                          <div class="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>
                              Anecdote 3
                            </span>
                            <input
                              type="text"
                              id="form1Example1"
                              class="form-control bg-light"
                              name="Anedotes3"
                              value={inputValuesAnedotes.Anedotes3}
                              onChange={handleInputChangeAnedotes}
                              required
                              rows="2"
                            />
                          </div>
                          <div class="form-outline mb-4">
                            <span style={{ fontSize: "15px" }}>
                              Anecdote 4
                            </span>
                            <input
                              type="text"
                              id="form1Example1"
                              class="form-control bg-light"
                              name="Anedotes4"
                              value={inputValuesAnedotes.Anedotes4}
                              onChange={handleInputChangeAnedotes}
                              required
                              rows="2"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div class="vl"> </div>
                &nbsp;&nbsp;&nbsp;
                <div class="p-2 w-50">
                  <div>
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Add TimeStamp
                    </h5>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Timestamp 1</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="Anedotestime1"
                        value={inputValuesAnedotes.Anedotestime1}
                        onChange={handleInputChangeAnedotes}
                        onKeyPress={(e) => {
                          const isValidChar = /^[0-9:]+$/.test(e.key);
                          if (!isValidChar) {
                            e.preventDefault();
                          }
                        }}
                        placeholder="MM:SS"
                        required
                        rows="2"
                        maxLength={5}
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Timestamp 2</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="Anedotestime2"
                        value={inputValuesAnedotes.Anedotestime2}
                        onChange={handleInputChangeAnedotes}
                        onKeyPress={(e) => {
                          const isValidChar = /^[0-9:]+$/.test(e.key);
                          if (!isValidChar) {
                            e.preventDefault();
                          }
                        }}
                        placeholder="MM:SS"
                        required
                        rows="2"
                        maxLength={5}
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Timestamp 3</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="Anedotestime3"
                        value={inputValuesAnedotes.Anedotestime3}
                        onChange={handleInputChangeAnedotes}
                        onKeyPress={(e) => {
                          const isValidChar = /^[0-9:]+$/.test(e.key);
                          if (!isValidChar) {
                            e.preventDefault();
                          }
                        }}
                        placeholder="MM:SS"
                        required
                        rows="2"
                        maxLength={5}
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Timestamp 4</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="Anedotestime4"
                        value={inputValuesAnedotes.Anedotestime4}
                        onChange={handleInputChangeAnedotes}
                        onKeyPress={(e) => {
                          const isValidChar = /^[0-9:]+$/.test(e.key);
                          if (!isValidChar) {
                            e.preventDefault();
                          }
                        }}
                        placeholder="MM:SS"
                        required
                        rows="2"
                        maxLength={5}
                      />
                    </div>
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div class="vl"> </div>
                &nbsp;&nbsp;&nbsp;
                <div class="p-2 w-50">
                  <div>
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Add Tags
                    </h5>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Tag 1</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="tags1"
                        value={inputValuesAnedotes.tags1}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Tag 2</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="tags2"
                        value={inputValuesAnedotes.tags2}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Tag 3</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="tags3"
                        value={inputValuesAnedotes.tags3}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Tag 4</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="tags4"
                        value={inputValuesAnedotes.tags4}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Tag 5</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="tags5"
                        value={inputValuesAnedotes.tags5}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div class="vl"> </div>
                &nbsp;&nbsp;&nbsp;
                <div class="p-2 w-50">
                  <div>
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Add Tags
                    </h5>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Tag 6</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="tags6"
                        value={inputValuesAnedotes.tags6}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Tag 7</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="tags7"
                        value={inputValuesAnedotes.tags7}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Tag 8</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="tags8"
                        value={inputValuesAnedotes.tags8}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Tag 9</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="tags9"
                        value={inputValuesAnedotes.tags9}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Tag 10</span>
                      <input
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="tags10"
                        value={inputValuesAnedotes.tags10}
                        onChange={handleInputChangeAnedotes}
                        required
                        rows="2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <center>
                <div class="form-outline mb-4">
                  <span style={{ fontSize: "15px" }}>suggestedLinks</span>
                  <input
                    type="text"
                    id="form1Example1"
                    class="form-control bg-secondary"
                    name="suggestedLinks"
                    value={inputValuesAnedotes.suggestedLinks}
                    onChange={handleInputChangeAnedotes}
                    required
                    rows="2"
                  />
                </div>
                <div class="form-outline mb-4">
                  <span style={{ fontSize: "15px" }}>Anecdotes Feedback (Optional)</span>
                  <input
                    type="text"
                    id="form1Example1"
                    class="form-control bg-secondary"
                    name="Annecdotesfeedback"
                    value={inputValuesAnedotes.Annecdotesfeedback}
                    onChange={handleInputChangeAnedotes}
                    required
                    rows="2"
                  />
                </div>
              </center>
              <center>
                <button
                  className="btn btn-primary"
                  onClick={() => saveanedotes()}
                >
                  Save
                </button>
              </center>
            </div>
          </div>
        )}

        {selectedQuiz && (
          <div className="popup">
            <div className="popup-content">
              <button
                className="btn-close text-white"
                onClick={closeRankingPopup}
              ></button>
              <br />
              <br />

              {/* <h6>Quiz name: {selectedQuiz}</h6> */}

              <div class="d-flex flex-row mb-3 bg-secondary">
                <div class="p-2">
                  <div>
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Ranking
                    </h5>

                    <div>
                      <div className="form-outline mb-4">
                        <span style={{ fontSize: "15px" }}>Delivery</span>
                        <br />
                        <Rate
                          allowHalf
                          count={5}
                          value={ranking.delivery}
                          onChange={(value) =>
                            handleRankingChange("delivery", value)
                          }
                          character={<i className="fas fa-star" />}
                          style={{ fontSize: 24, color: "yellow" }}
                        />
                      </div>

                      <div className="form-outline mb-4">
                        <span style={{ fontSize: "15px" }}>
                          Content Quality
                        </span>
                        <br />
                        <Rate
                          allowHalf
                          count={5}
                          value={ranking.content}
                          onChange={(value) =>
                            handleRankingChange("content", value)
                          }
                          character={<i className="fas fa-star" />}
                          style={{ fontSize: 24, color: "yellow" }}
                        />
                      </div>

                      <div className="form-outline mb-4">
                        <span style={{ fontSize: "15px" }}>
                          Engagement Level
                        </span>
                        <br />
                        <Rate
                          allowHalf
                          count={5}
                          value={ranking.level}
                          onChange={(value) =>
                            handleRankingChange("level", value)
                          }
                          character={<i className="fas fa-star" />}
                          style={{ fontSize: 24, color: "yellow" }}
                        />
                      </div>

                      <div className="form-outline mb-4">
                        <span style={{ fontSize: "15px" }}>Duration</span>
                        <br />
                        <Rate
                          allowHalf
                          count={5}
                          value={ranking.duration}
                          onChange={(value) =>
                            handleRankingChange("duration", value)
                          }
                          character={<i className="fas fa-star" />}
                          style={{ fontSize: 24, color: "yellow" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div class="vl"> </div>
                &nbsp;&nbsp;&nbsp;
                <div class="p-2 w-50">
                  <div>
                    <h5
                      className="modal-title text-center"
                      id="exampleModalLabel"
                    >
                      Justification
                    </h5>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Delivery</span>
                      <textarea
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="Delivery"
                        value={inputValues.Delivery}
                        onChange={handleInputChange}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Content Quality</span>
                      <textarea
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="Content"
                        value={inputValues.Content}
                        onChange={handleInputChange}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Engagement Level</span>
                      <textarea
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="level"
                        value={inputValues.level}
                        onChange={handleInputChange}
                        required
                        rows="2"
                      />
                    </div>
                    <div class="form-outline mb-4">
                      <span style={{ fontSize: "15px" }}>Duration</span>
                      <textarea
                        type="text"
                        id="form1Example1"
                        class="form-control bg-light"
                        name="Duration"
                        value={inputValues.Duration}
                        onChange={handleInputChange}
                        required
                        rows="2"
                      />
                    </div>

                  </div>
                </div>

              </div>
              <div className="average-ranking bg-primary text-white">
                <h6>Average Ranking: {calculateAverageRanking()}</h6>
                <Rate
                  allowHalf
                  count={5}
                  value={parseFloat(calculateAverageRanking())}
                  character={<i className="fas fa-star" />}
                  style={{ fontSize: 24, color: "yellow" }}
                />

              </div>
              <center>
                <br />
                {/* <label>Video Ranking Feedback (Optional)</label> */}
                <div class="form-outline mb-4">
                  <span style={{ fontSize: "15px" }}>Ranking Feedback (Optional)</span>
                  <textarea
                    type="text"
                    id="form1Example1"
                    class="form-control bg-secondary"
                    name="rankingfeedback"
                    value={inputValues.rankingfeedback}
                    onChange={handleInputChange}
                    required
                    // placeholder="Video Ranking Feedback (Optional)"
                    rows="2"
                  />
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => savevideoranking()}
                >
                  Save
                </button>
              </center>
            </div>
          </div>
        )}
        {/* <NotificationContainer /> */}

      </div>
    </>
  );
}

export default CardDetailsPage;