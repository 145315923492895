import axios from 'axios';
// import getUserIdFromToken from '../Utils/JWT/jwt';
import getUserIdFromToken from '../../Utils/JWT/jwt';
import { baseURL } from "../../Config/config"

export const FETCH_RANKINGDATA_REQUEST = 'FETCH_RANKINGDATA_REQUEST';
export const FETCH_RANKINGDATA_SUCCESS = 'FETCH_RANKINGDATA_SUCCESS';
export const FETCH_RANKINGDATA_FAILURE = 'FETCH_RANKINGDATA_FAILURE';

export const fetchrankingdataRequest = () => ({
    type: FETCH_RANKINGDATA_REQUEST,
});

export const fetchrankingdataSuccess = (data) => ({
    type: FETCH_RANKINGDATA_SUCCESS,
    payload: data,
});

export const fetchrankingdataFailure = (error) => ({
    type: FETCH_RANKINGDATA_FAILURE,
    payload: error,
});

export const fetchrankingdata = (learningId, setRankingData, setShowPopup) => {
    return (dispatch) => {
        const userId = getUserIdFromToken();
        dispatch(fetchrankingdataRequest());
        const accessTokenWithQuotes = localStorage.getItem("user");
        const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
        axios
            .get(`${baseURL}/Admin/getranking/Completed/learningID/${learningId}/${userId}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessTokenWithoutQuotes}`,
                },
            })
            .then((response) => {
                const responseData = response.data;
                if (Array.isArray(responseData.data)) {
                    const data = responseData.data;
                    dispatch(fetchrankingdataSuccess(data));
                    setRankingData(data);
                    setShowPopup(true);
                    console.log("+_______________________________________________________________+", data);
                } else {
                    dispatch(fetchrankingdataFailure('Data received is not an array'));
                }
            })
            .catch((error) => {
                dispatch(fetchrankingdataFailure(error.message));
            });
    };
};



export const FETCH_ANECDOTESDATA_REQUEST = 'FETCH_ANECDOTESDATA_REQUEST';
export const FETCH_ANECDOTESDATA_SUCCESS = 'FETCH_ANECDOTESDATA_SUCCESS';
export const FETCH_ANECDOTESDATA_FAILURE = 'FETCH_ANECDOTESDATA_FAILURE';

export const fetchanecdotesdataRequest = () => ({
    type: FETCH_ANECDOTESDATA_REQUEST,
});

export const fetchanecdotesdataSuccess = (data) => ({
    type: FETCH_ANECDOTESDATA_SUCCESS,
    payload: data,
});

export const fetchanecdotesdataFailure = (error) => ({
    type: FETCH_ANECDOTESDATA_FAILURE,
    payload: error,
});

// export const fetchanecdotesdata = (learningId, setAnecdotesData, setShowAnecdotesPopup) => {
//     return (dispatch) => {
//       const userId = getUserIdFromToken();
//       dispatch(fetchanecdotesdataRequest());
//       const accessTokenWithQuotes = localStorage.getItem("user");
//       const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");
//       axios
//         .get(`${baseURL}/Admin/get/Annedotes/learningID/${learningId}/${userId}`, {
//           headers: {
//             Accept: 'application/json',
//             Authorization: `Bearer ${accessTokenWithoutQuotes}`,
//           },
//         })
//         .then((response) => {
//             const responseData = response.data;
//             if (Array.isArray(responseData.data) && responseData.data.length > 0) {
//               const data = responseData.data;
//               console.log(">>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<Id", data[0].AnecdotesId);
//               dispatch(fetchanecdotesdataSuccess(data));
//               setShowAnecdotesPopup(true);
//               setAnecdotesData(data);
//             } else {
//               console.error("Data received is not an array or is empty:", responseData);
//               setShowAnecdotesPopup(true); 
    
//             }
//           })
//         .catch((error) => {
//           dispatch(fetchanecdotesdataFailure(error.message));
//         });
//     };
//   };
export const fetchanecdotesdata = (learningId, setAnecdotesData, setShowAnecdotesPopup) => {
    return (dispatch) => {
      const userId = getUserIdFromToken();
      dispatch(fetchanecdotesdataRequest());
      const accessTokenWithQuotes = localStorage.getItem("user");
      const accessTokenWithoutQuotes = accessTokenWithQuotes.replace(/"/g, "");

      axios
        .get(`${baseURL}/Admin/get/Annedotes/learningID/${learningId}/${userId}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessTokenWithoutQuotes}`,
          },
        })
        .then((response) => {
          const responseData = response.data;
  
          if (Array.isArray(responseData.data) && responseData.data.length > 0) {
            const data = responseData.data;
            console.log("Anecdotes Id:", data[0].AnecdotesId);
            dispatch(fetchanecdotesdataSuccess(data));
            setAnecdotesData(data);
          } else {
            console.error("Data received is not an array or is empty:", responseData);
          }
          setShowAnecdotesPopup(true);

        })
        .catch((error) => {
          dispatch(fetchanecdotesdataFailure(error.message));
        });
    };
  };
  