import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMyReview,
} from "../../Redux/Action/MyReview.action";

function MyReviews() {

  const dispatch = useDispatch();
  const { isLoading, error, data } = useSelector((state) => state.myreview);
  useEffect(() => {
    dispatch(fetchMyReview());
  }, [dispatch]);


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (data) {
    return (
      <div>
        <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header bg-primary text-white text-center">My Reviews</div>
            <div className="container">
              <div className="row">
              <div className="col bg-danger text-white d-flex flex-column justify-content-center align-items-center p-4" style={{ height: "200px" }}>
              <Link to="/DataTableQuiz">

                  <h4 className='text-white'>Pending: {data.AvailableCount}</h4>
                  </Link>
                </div>
                <div className="col bg-success text-white d-flex flex-column justify-content-center align-items-center p-4" style={{ height: "200px" }}>
                    <Link to="/DataTableEdit">
                    <h4 className='text-white'>Completed: {data.Completed}</h4>
                    </Link>
                </div>
                <div className="col bg-primary text-white d-flex flex-column justify-content-center align-items-center p-4" style={{ height: "200px" }}>
                  <h4>Total Video: {data.TotalCount}</h4>
                </div>
                {/* <div className="col bg-info  text-white d-flex flex-column justify-content-center align-items-center p-4" style={{ height: "200px" }}>
                  <h4>Overall Video: {data.Overallcount}</h4>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    );
  }
  return <div>No data available.</div>;
}

export default MyReviews;
